



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {PrivateCategory} from '@/model/resource/PrivateCategory'
import {InputPrivateCategorySchema} from '@/schema/resource/PrivateCategory/InputPrivateCategorySchema'

@Component
export default class PersistPrivateCategoryView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputPrivateCategorySchema()
  privateCategory = new PrivateCategory()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.privateCategory.getPrivateCategory(id)
    }

    this.$await.done('getPrivateCategory')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.privateCategory.persistPrivateCategory()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/private-category/list')
  }
}
